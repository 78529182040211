import * as api from '@utils/api'
import { ApiDispatch, ApiDispatchReturn, AppDispatch } from '@types'

export interface APIActionType {
  url: string
  method: string
  params?: any
  data?: any
  actionCallbacks: {
    request?: () => any
    success: (data: any, response?: any) => any
    failure?: (_: any, err: Error) => any
  }
  resource?: any
}

export const apiAction =
  <ReturnType = unknown>({
    url,
    method,
    params,
    data,
    actionCallbacks: { request, success, failure },
    resource,
  }: APIActionType): ApiDispatch | ApiDispatchReturn<ReturnType> =>
  async (dispatch: AppDispatch): Promise<AppDispatch | void | ReturnType> => {
    try {
      if (request) {
        await dispatch(request())
      }

      const { data: payload, ...response } = await api.CALL({
        url,
        method,
        params,
        data,
      })

      await dispatch(
        success(resource ? { payload, resource } : payload, response),
      )
      return payload
    } catch (err) {
      if (!failure) {
        throw err
      } else {
        dispatch(failure(null, err as Error))
      }
    }
  }
