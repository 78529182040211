import React, { ReactElement, useEffect, useState } from 'react'
import ResponsiveDialog from '@components/shared/responsive-dialog'
import useSelector from '@hooks/use-selector'
import { AppDispatch, AttributeValueType } from '@types'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { getAttributes } from '@store/actions/attributes'
import { selectAttribute } from '@store/selectors/attributes'
import { useDispatch } from 'react-redux'

const PreviewAddProductAttributeValueDialog = ({
  open,
  onCancel: handleCancel,
  onConfirm,
  attributeId,
}: Props): ReactElement => {
  const attribute = useSelector(selectAttribute, attributeId)
  const attributeValues = attribute?.attribute_values
  const dispatch = useDispatch<AppDispatch>()
  const [selectedAttribute, setSelectedAttribute] = useState(0)

  useEffect(() => {
    if (attributeValues && attributeValues.length) return

    dispatch(getAttributes())
  }, [])

  const handleAttributeChange = ({
    target: { value },
  }: SelectChangeEvent<number>) => {
    setSelectedAttribute(value as number)
  }

  const handleConfirm = () => {
    const selected = attributeValues?.find((x) => x.id == selectedAttribute)
    if (!selected) return

    onConfirm(selected)
  }

  return (
    <ResponsiveDialog open={open}>
      <DialogTitle>
        <Typography align='center'>Select New Attribute Value</Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Value</InputLabel>
          <Select onChange={handleAttributeChange} value={selectedAttribute}>
            {attributeValues?.map((attribute) => (
              <MenuItem key={attribute.id} value={attribute.id}>
                {attribute.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button color='secondary' onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default PreviewAddProductAttributeValueDialog

type Props = {
  open: boolean
  onCancel: () => void
  onConfirm: (attributeValue: AttributeValueType) => void
  attributeId: number
}
