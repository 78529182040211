import createAction from './create-action'
import {
  ApiDispatch,
  NewPromotionalCategoryType,
  PromotionalCategoryType,
} from '@types'
import {
  CREATE_PROMOTIONAL_CATEGORY,
  CREATE_PROMOTIONAL_CATEGORY_FAILURE,
  DELETE_PROMOTIONAL_CATEGORY,
  DELETE_PROMOTIONAL_CATEGORY_FAILURE,
  GET_PROMOTIONAL_CATEGORIES,
  GET_PROMOTIONAL_CATEGORIES_FAILURE,
  UPDATE_PROMOTIONAL_CATEGORY,
  UPDATE_PROMOTIONAL_CATEGORY_FAILURE,
} from '@store/action-list'
import { apiAction } from './api'

export const getPromotionalCategoriesAction = createAction<
  PromotionalCategoryType[]
>(GET_PROMOTIONAL_CATEGORIES)
const getPromotionalCategoriesFailureAction = createAction(
  GET_PROMOTIONAL_CATEGORIES_FAILURE,
)

export const createPromotionalCategoryAction =
  createAction<PromotionalCategoryType>(CREATE_PROMOTIONAL_CATEGORY)
const createPromotionalCategoryFailureAction = createAction(
  CREATE_PROMOTIONAL_CATEGORY_FAILURE,
)

export const updatePromotionalCategoryAction =
  createAction<PromotionalCategoryType>(UPDATE_PROMOTIONAL_CATEGORY)
const updatePromotionalCategoryFailureAction = createAction(
  UPDATE_PROMOTIONAL_CATEGORY_FAILURE,
)

export const deletePromotionalCategoryAction =
  createAction<PromotionalCategoryType>(DELETE_PROMOTIONAL_CATEGORY)
const deletePromotionalCategoryFailureAction = createAction(
  DELETE_PROMOTIONAL_CATEGORY_FAILURE,
)

export const getPromotionalCategories = (): ApiDispatch =>
  apiAction({
    url: '/p4/promotionalCategories',
    method: 'GET',
    actionCallbacks: {
      success: getPromotionalCategoriesAction,
      failure: getPromotionalCategoriesFailureAction,
    },
  })

export const createPromotionalCategory = (
  category: NewPromotionalCategoryType,
): ApiDispatch =>
  apiAction({
    url: '/p4/promotionalCategories',
    method: 'POST',
    data: category,
    actionCallbacks: {
      success: createPromotionalCategoryAction,
      failure: createPromotionalCategoryFailureAction,
    },
  })

export const updatePromotionalCategory = (
  category: PromotionalCategoryType,
): ApiDispatch =>
  apiAction({
    url: `/p4/promotionalCategories/${category.id}`,
    method: 'PUT',
    data: category,
    actionCallbacks: {
      success: updatePromotionalCategoryAction,
      failure: updatePromotionalCategoryFailureAction,
    },
  })

export const deletePromotionalCategory = (
  category: PromotionalCategoryType,
): ApiDispatch =>
  apiAction({
    url: `/p4/promotionalCategories/${category.id}`,
    method: 'DELETE',
    data: category,
    actionCallbacks: {
      success: deletePromotionalCategoryAction,
      failure: deletePromotionalCategoryFailureAction,
    },
  })
