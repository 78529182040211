import createCachedSelector from 're-reselect'
import { RootState, VariantType } from '@types'

type VariantsState = Record<string, Record<number, VariantType>>

const variantsSelector = (state: RootState): VariantsState => state.Variants

export const selectProductVariants = createCachedSelector(
  variantsSelector,
  (_, productId) => productId,
  (variants, productId) =>
    variants[productId] ? Object.values(variants[productId]) : null,
)((_, productId) => productId)
