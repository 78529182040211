import DndTable from '@components/shared/dnd-table'
import PreviewAddProductAttributeDialog from './add-product-attribute-dialog'
import React, { ReactElement, useState } from 'react'
import { Add, Delete } from '@mui/icons-material'
import { AppDispatch, ProductAttributeType, ProductTypeType } from '@types'
import {
  deleteProductAttribute,
  updateProductAttribute,
} from '@store/actions/product-attributes'
import { useConfirm } from '@components/shared/confirm-dialog'
import { useDispatch } from 'react-redux'

const PreviewAttributes = ({
  productAttributes,
  onAddAttribute,
  productType,
  onClick,
  editable = false,
}: Props): ReactElement => {
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const confirm = useConfirm()
  const dispatch = useDispatch<AppDispatch>()

  const handleSort = (sortedProductAttributes: ProductAttributeType[]) => {
    sortedProductAttributes.forEach((sortedProductAttribute, i) => {
      dispatch(
        updateProductAttribute({
          ...sortedProductAttribute,
          sort_order: i,
        }),
      )
    })
  }
  const handleRowClick = (productAttributeId: number) => {
    const clicked = productAttributes.find((x) => x.id == productAttributeId)
    if (clicked) onClick(clicked)
  }
  const handleAddDialogOpen = () => setAddDialogOpen(true)
  const handleAddCancel = () => setAddDialogOpen(false)
  const handleAddConfirm = (attributeId: number) => {
    onAddAttribute(attributeId)
    setAddDialogOpen(false)
  }
  const handleDelete = ({ original: productAttribute }) => {
    confirm({
      title: 'Be Careful',
      message:
        'Are you sure you want to delete this attribute? If there are any product variants relying on this attribute it could impact the menu immediately.',
      onConfirm: () => {
        dispatch(deleteProductAttribute(productAttribute))
      },
    })
  }

  return (
    <>
      <PreviewAddProductAttributeDialog
        onCancel={handleAddCancel}
        onConfirm={handleAddConfirm}
        open={addDialogOpen}
        productType={productType}
      />
      <DndTable
        actions={
          editable
            ? [
                {
                  title: 'Add Attribute Type',
                  icon: <Add />,
                  toolbarAction: true,
                  onClick: handleAddDialogOpen,
                },
                {
                  title: 'Delete',
                  icon: <Delete />,
                  toolbarAction: false,
                  onClick: handleDelete,
                },
              ]
            : []
        }
        afterDrop={handleSort}
        columns={[{ Header: 'Name', accessor: 'attribute.name' }]}
        data={productAttributes}
        disableDrag={!editable}
        hideBorders
        hideHeaders
        layout='tight'
        rowClick={handleRowClick}
        sortColumn={['sort_order']}
        sortDir={['asc']}
        title='Attributes'
      />
    </>
  )
}

export default PreviewAttributes

type Props = {
  editable?: boolean
  productAttributes: ProductAttributeType[]
  onAddAttribute: (attributeId: number) => void
  productType: ProductTypeType
  onClick: (productAttribute: ProductAttributeType) => void
}
