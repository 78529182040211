import { PromotionalCategoriesStateType } from '@types'
import { arrayToObject } from '@utils/functional'
import {
  createPromotionalCategoryAction,
  deletePromotionalCategoryAction,
  getPromotionalCategoriesAction,
  updatePromotionalCategoryAction,
} from '@store/actions/promotional-categories'
import { createReducer } from '@reduxjs/toolkit'

const initialState = {} as PromotionalCategoriesStateType

const setOneCategory = (state, { payload: category }) => {
  return { ...state, [category.id]: category }
}

const promotionalCategoriesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPromotionalCategoriesAction, (_, { payload: attributes }) => {
      return arrayToObject('id', attributes)
    })
    .addCase(createPromotionalCategoryAction, setOneCategory)
    .addCase(updatePromotionalCategoryAction, setOneCategory)
    .addCase(
      deletePromotionalCategoryAction,
      (state, { payload: category }) => {
        const filteredState = { ...state }
        delete filteredState[category.id]
        return filteredState
      },
    )
})

export default promotionalCategoriesReducer
