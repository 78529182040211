import React, { useCallback, useEffect, useMemo, useState } from 'react'
import orderBy from 'lodash.orderby'
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { makeStyles } from 'tss-react/mui'
import { useTable } from 'react-table'

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'

import Row from './row'

const useStyles = makeStyles()(() => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    padding: '0 16px',
  },
}))

const DndTable = ({
  title,
  layout,
  hideHeaders,
  columns,
  data,
  afterDrop,
  sortColumn,
  sortDir,
  actions,
  rowClick,
  hideBorders,
  disableDrag = false,
}) => {
  const setOrderBy = (records) => orderBy(records, sortColumn, sortDir)
  const [records, setRecords] = useState(setOrderBy(data))

  const setSortedRecords = (records) => setRecords(setOrderBy(records))
  const getRowId = useCallback((row) => {
    return row.id
  }, [])
  const { classes } = useStyles()

  useEffect(() => {
    setSortedRecords(data)
  }, [data])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      data: records,
      columns,
      getRowId,
    })

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex]

    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      }),
    )
  }

  const end = () => {
    afterDrop(records)
  }

  const toolbarActions = useMemo(
    () => actions.filter((action) => action.toolbarAction === true),
    [actions],
  )
  const rowActions = useMemo(
    () => actions.filter((action) => action.toolbarAction === false),
    [actions],
  )
  const tableProps = getTableProps()
  const tableBodyProps = getTableBodyProps()

  const toolbarClass = () => {
    const cssClass = [classes.toolBar]
    if (hideHeaders) {
      cssClass.push(classes.borderBottom)
    }
    return cssClass.join(' ')
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <TableContainer {...tableProps} component={hideBorders ? 'div' : Paper}>
        {title && (
          <Toolbar className={toolbarClass()}>
            <Typography id='tableTitle' variant='h6'>
              {title}
            </Typography>
            <div>
              {toolbarActions.map((action) => {
                return (
                  <Tooltip key={action.title} title={action.title}>
                    <IconButton
                      aria-label={action.title}
                      onClick={action.onClick}
                    >
                      {action.icon}
                    </IconButton>
                  </Tooltip>
                )
              })}
            </div>
          </Toolbar>
        )}
        <Table>
          {!hideHeaders && (
            <TableHead>
              {headerGroups.map((headerGroup, idx1) => (
                <TableRow key={idx1} {...headerGroup.getHeaderGroupProps()}>
                  {!disableDrag ? <TableCell>Sort</TableCell> : null}
                  {headerGroup.headers.map((column, idx2) => (
                    <TableCell
                      key={`${idx1}-${idx2}`}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              ))}
            </TableHead>
          )}
          <TableBody {...tableBodyProps}>
            {rows.map((row, index) => {
              prepareRow(row)

              return (
                <Row
                  afterDrop={end}
                  className={classes.row}
                  disableDrag={disableDrag}
                  index={index}
                  key={row.id}
                  layout={layout}
                  moveRow={moveRow}
                  onClick={rowClick}
                  row={row}
                  rowActions={rowActions}
                  {...row.getRowProps()}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </DndProvider>
  )
}
export default DndTable
