import { VariantType } from '@types'
import { arrayToObject } from '@utils/functional'
import { createReducer } from '@reduxjs/toolkit'
import {
  createVariantAction,
  deleteVariantAction,
  getProductVariantsAction,
  setVariantAction,
  updateVariantAction,
} from '@store/actions/variants'

type State = Record<number, Record<number, VariantType>>

const initialState = {} as State

const setOneVariant = (state, { payload: variant }) => {
  const productVariants = {
    ...state[variant.product_id],
    [variant.id]: variant,
  }
  return { ...state, [variant.product_id]: productVariants }
}

const variantsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getProductVariantsAction,
      (state, { payload: { payload: variants, resource: productId } }) => {
        return {
          ...state,
          [productId]: arrayToObject('id', variants),
        }
      },
    )
    .addCase(setVariantAction, setOneVariant)
    .addCase(updateVariantAction, setOneVariant)
    .addCase(createVariantAction, setOneVariant)
    .addCase(deleteVariantAction, (state, { payload: variant }) => {
      const productVariants = {
        ...state[variant.product_id],
      }
      delete productVariants[variant.id]
      return { ...state, [variant.product_id]: productVariants }
    })
})

export default variantsReducer
