import React, { ChangeEvent, ReactElement } from 'react'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { ProductTypeType } from '@types'

const AttributeForm = ({
  name,
  label,
  productType,
  onNameChange,
  onLabelChange,
  onProductTypeChange,
}: Props): ReactElement => {
  const handleProductTypeChange = ({
    target: { value },
  }: SelectChangeEvent) => {
    onProductTypeChange(value as ProductTypeType)
  }

  const handleNameChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onNameChange(value as string)
  }

  const handleLabelChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onLabelChange(value as string)
  }

  return (
    <Grid container spacing={5}>
      <Grid item lg={4} xs={12}>
        <TextField
          fullWidth
          label='Name'
          onChange={handleNameChange}
          value={name}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <TextField
          fullWidth
          label='Label'
          onChange={handleLabelChange}
          value={label}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <FormControl fullWidth>
          <InputLabel>Product Type</InputLabel>
          <Select
            fullWidth
            onChange={handleProductTypeChange}
            value={productType}
          >
            <MenuItem value='beverage'>Beverage</MenuItem>
            <MenuItem value='bag'>Bag</MenuItem>
            <MenuItem value='food'>Food</MenuItem>
            <MenuItem value='bottle'>Bottle</MenuItem>
            <MenuItem value='merchandise'>Merchandise</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default AttributeForm

type Props = {
  name: string
  label: string
  productType: string
  onNameChange: (newValue: string) => void
  onLabelChange: (newValue: string) => void
  onProductTypeChange: (newValue: ProductTypeType) => void
}
