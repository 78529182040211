import PreviewAttributeValues from './attribute-values'
import PreviewAttributes from './attributes'
import PreviewEmptyProductAttributes from './empty'
import PreviewVariants from './variants'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import useSelector from '@hooks/use-selector'
import { AppDispatch, ProductAttributeType, ProductType } from '@types'
import { Grid, Paper } from '@mui/material'
import {
  cloneProductAttributes,
  createProductAttribute,
  getProductAttributes,
} from '@store/actions/product-attributes'
import { makeStyles } from 'tss-react/mui'
import { selectProductAttributes } from '@store/selectors/product-attributes'
import { useDispatch } from 'react-redux'

const PreviewProductAttributes = ({
  product,
  editable,
}: Props): ReactElement => {
  const { classes } = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const productAttributes = useSelector((state) =>
    selectProductAttributes(state, product.id),
  )
  const [currentAttr, setCurrentAttr] = useState<number | null>(null)

  useEffect(() => {
    dispatch(getProductAttributes(product))
  }, [product])

  const currentProductAttribute = useMemo(() => {
    if (!currentAttr && productAttributes?.length) {
      setCurrentAttr(productAttributes[0].id)
      return
    }

    const selectedAttr = productAttributes?.find((x) => x.id == currentAttr)
    if (!selectedAttr && productAttributes?.length) {
      // an edge case where we deleted the current selection
      setCurrentAttr(productAttributes[0].id)
      return
    }

    return selectedAttr
  }, [productAttributes, currentAttr])

  const handleAddAttribute = (attributeId: number, sortOrder: number = 0) => {
    dispatch(
      createProductAttribute({
        attribute_id: attributeId,
        product_id: product.id,
        sort_order: sortOrder,
      }),
    )
  }

  const handleCloneProduct = (productId: number) => {
    dispatch(
      cloneProductAttributes({
        productId: product.id,
        cloneProductId: productId,
      }),
    )
  }

  const attrName = (productAttribute: ProductAttributeType | null): string => {
    if (!productAttribute) return ''

    return `${productAttribute.attribute?.name} {{${productAttribute.attribute?.label}}}`
  }

  const handleAttributeClick = (productAttribute: ProductAttributeType) =>
    setCurrentAttr(productAttribute.id)

  return (
    <Paper className={classes.container}>
      <Grid className={classes.innerContainer} container>
        {!productAttributes ||
        !productAttributes.length ||
        !currentProductAttribute ? (
          editable && (
            <Grid item xs={12}>
              <PreviewEmptyProductAttributes
                onAddAttribute={handleAddAttribute}
                onCloneProduct={handleCloneProduct}
                productType={product.product_type}
              />
            </Grid>
          )
        ) : (
          <Grid container>
            <Grid item xs={2}>
              <PreviewAttributes
                editable={editable}
                onAddAttribute={handleAddAttribute}
                onClick={handleAttributeClick}
                productAttributes={productAttributes}
                productType={product.product_type}
              />
            </Grid>
            <Grid className={classes.attributeValuesContainer} item xs={10}>
              <PreviewAttributeValues
                attributeId={currentProductAttribute.attribute_id}
                attributeValues={
                  currentProductAttribute.product_attribute_values
                }
                editable={editable}
                productAttributeId={currentProductAttribute.id}
                title={attrName(currentProductAttribute)}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid className={classes.innerContainer} container>
        <Grid item xs={12}>
          <PreviewVariants editable={editable} product={product} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PreviewProductAttributes

type Props = {
  product: ProductType
  editable: boolean
}

const useStyles = makeStyles()((theme) => ({
  container: {
    // belive me or not paddingHorizontal doesn't work on Paper
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    overflow: 'hidden',
  },
  innerContainer: {
    border: '1px solid rgba(224, 224, 224, 1)',
    marginBottom: theme.spacing(3),
  },
  attributeValuesContainer: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    paddingVertical: theme.spacing(1),
    paddingHorizontal: theme.spacing(3),
  },
}))
