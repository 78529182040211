import createCachedSelector from 're-reselect'
import { AttributeType, RootState } from '@types'
import { createSelector } from 'reselect'
import { identity } from '@utils/functional'

const attributeSelector = (
  state: RootState,
  id: number,
): AttributeType | undefined => state.Attributes[id]
const attributesSelector = (state: RootState): Record<number, AttributeType> =>
  state.Attributes

export const selectAttributes = createSelector(
  attributesSelector,
  (attributes) => Object.values(attributes),
)

export const selectAttribute = createCachedSelector(
  attributeSelector,
  identity,
)((_, id) => id)

export const selectAttributesByProductType = createCachedSelector(
  attributesSelector,
  (_, productType) => productType,
  (attributes, productType) =>
    Object.values(attributes).filter((a) => a.product_type == productType),
)((_, productType) => productType)
