import createAction from './create-action'
import {
  ActionPayloadWithResource,
  ApiDispatch,
  NewVariantType,
  ProductType,
  VariantType,
} from '@types'
import {
  CREATE_VARIANT,
  CREATE_VARIANT_FAILURE,
  DELETE_VARIANT,
  DELETE_VARIANT_FAILURE,
  GET_PRODUCT_VARIANTS,
  GET_PRODUCT_VARIANTS_FAILURE,
  SET_VARIANT,
  UPDATE_VARIANT,
  UPDATE_VARIANT_FAILURE,
} from '@store/action-list'
import { apiAction } from './api'

export const setVariantAction = createAction<VariantType>(SET_VARIANT)

export const getProductVariantsAction =
  createAction<ActionPayloadWithResource<VariantType[], number>>(
    GET_PRODUCT_VARIANTS,
  )
const getProductVariantsFailureAction = createAction(
  GET_PRODUCT_VARIANTS_FAILURE,
)

export const createVariantAction = createAction<VariantType>(CREATE_VARIANT)
const createVariantFailureAction = createAction(CREATE_VARIANT_FAILURE)

export const updateVariantAction = createAction<VariantType>(UPDATE_VARIANT)
const updateVariantFailureAction = createAction(UPDATE_VARIANT_FAILURE)

export const deleteVariantAction = createAction<VariantType>(DELETE_VARIANT)
const deleteVariantFailureAction = createAction(DELETE_VARIANT_FAILURE)

export const getProductVariants = (product: ProductType): ApiDispatch =>
  apiAction({
    url: `/p4/preview/products/${product.id}/variants`,
    method: 'GET',
    actionCallbacks: {
      success: getProductVariantsAction,
      failure: getProductVariantsFailureAction,
    },
    resource: product.id,
  })

export const createVariant = (variant: NewVariantType): ApiDispatch =>
  apiAction({
    url: '/p4/preview/variants',
    method: 'POST',
    data: variant,
    actionCallbacks: {
      success: createVariantAction,
      failure: createVariantFailureAction,
    },
  })

export const updateVariant = (variant: VariantType): ApiDispatch =>
  apiAction({
    url: `/p4/preview/variants/${variant.id}`,
    method: 'PUT',
    data: variant,
    actionCallbacks: {
      success: updateVariantAction,
      failure: updateVariantFailureAction,
    },
  })

export const deleteVariant = (variant: VariantType): ApiDispatch =>
  apiAction({
    url: `/p4/preview/variants/${variant.id}`,
    method: 'DELETE',
    actionCallbacks: {
      success: deleteVariantAction,
      failure: deleteVariantFailureAction,
    },
  })
