import { PromotionalCategoriesStateType, RootState } from '@types'
import { createSelector } from 'reselect'

const stateSelector = (state: RootState): PromotionalCategoriesStateType =>
  state.PromotionalCategories

export const selectPromotionalCategories = createSelector(
  stateSelector,
  (categories) => Object.values(categories),
)
