import createAction from './create-action'
import { ApiDispatch, AttributeType, NewAttributeType } from '@types'
import {
  CREATE_ATTRIBUTE,
  CREATE_ATTRIBUTE_FAILURE,
  DELETE_ATTRIBUTE,
  DELETE_ATTRIBUTE_FAILURE,
  GET_ATTRIBUTES,
  GET_ATTRIBUTES_FAILURE,
  SET_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE_FAILURE,
} from '@store/action-list'
import { apiAction } from './api'

export const setAttributeAction = createAction<AttributeType>(SET_ATTRIBUTE)

export const getAttributesAction = createAction<AttributeType[]>(GET_ATTRIBUTES)
const getAttributesFailureAction = createAction(GET_ATTRIBUTES_FAILURE)

export const createAttributeAction =
  createAction<AttributeType>(CREATE_ATTRIBUTE)
const createAttributeFailureAction = createAction(CREATE_ATTRIBUTE_FAILURE)

export const updateAttributeAction =
  createAction<AttributeType>(UPDATE_ATTRIBUTE)
const updateAttributeFailureAction = createAction(UPDATE_ATTRIBUTE_FAILURE)

export const deleteAttributeAction =
  createAction<AttributeType>(DELETE_ATTRIBUTE)
const deleteAttributeFailureAction = createAction(DELETE_ATTRIBUTE_FAILURE)

export const getAttributes = (): ApiDispatch =>
  apiAction({
    url: '/p4/preview/attributes',
    method: 'GET',
    actionCallbacks: {
      success: getAttributesAction,
      failure: getAttributesFailureAction,
    },
  })

export const createAttribute = (attribute: NewAttributeType): ApiDispatch =>
  apiAction({
    url: '/p4/preview/attributes',
    method: 'POST',
    data: attribute,
    actionCallbacks: {
      success: createAttributeAction,
      failure: createAttributeFailureAction,
    },
  })

export const updateAttribute = (attribute: AttributeType): ApiDispatch =>
  apiAction({
    url: `/p4/preview/attributes/${attribute.id}`,
    method: 'PUT',
    data: attribute,
    actionCallbacks: {
      success: updateAttributeAction,
      failure: updateAttributeFailureAction,
    },
  })

export const deleteAttribute = (attribute: AttributeType): ApiDispatch =>
  apiAction({
    url: `/p4/preview/attributes/${attribute.id}`,
    method: 'DELETE',
    actionCallbacks: {
      success: deleteAttributeAction,
      failure: deleteAttributeFailureAction,
    },
  })
