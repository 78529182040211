import createCachedSelector from 're-reselect'
import { ProductAttributeType, RootState } from '@types'

type ProductAttributesState = Record<
  string,
  Record<number, ProductAttributeType>
>

const productAttributesSelector = (state: RootState): ProductAttributesState =>
  state.ProductAttributes

export const selectProductAttributes = createCachedSelector(
  productAttributesSelector,
  (_, productId) => productId,
  (productAttributes, productId) =>
    productAttributes[productId]
      ? Object.values(productAttributes[productId])
      : null,
)((_, productId) => productId)
