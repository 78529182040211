import { AttributeType } from '@types'
import { arrayToObject } from '@utils/functional'
import {
  createAttributeAction,
  deleteAttributeAction,
  getAttributesAction,
  setAttributeAction,
  updateAttributeAction,
} from '@store/actions/attributes'
import { createReducer } from '@reduxjs/toolkit'

type State = Record<number, AttributeType>

const initialState = {} as State

const setOneAttribute = (state, { payload: attribute }) => {
  return { ...state, [attribute.id]: attribute }
}

const attributesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAttributesAction, (_, { payload: attributes }) => {
      return arrayToObject('id', attributes)
    })
    .addCase(setAttributeAction, setOneAttribute)
    .addCase(updateAttributeAction, setOneAttribute)
    .addCase(createAttributeAction, setOneAttribute)
    .addCase(deleteAttributeAction, (state, { payload: attribute }) => {
      const filteredState = { ...state }
      delete filteredState[attribute.id]
      return filteredState
    })
})

export default attributesReducer
