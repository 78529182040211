import MaterialTable from '@material-table/core'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import useSelector from '@hooks/use-selector'
import {
  AppDispatch,
  ProductAttributeType,
  ProductType,
  VariantAttributeValue,
  VariantType,
} from '@types'
import {
  FormControl,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import {
  createVariant,
  deleteVariant,
  getProductVariants,
  updateVariant,
} from '@store/actions/variants'
import { makeStyles } from 'tss-react/mui'
import { selectProductAttributes } from '@store/selectors/product-attributes'
import { selectProductVariants } from '@store/selectors/variants'
import { useConfirm } from '@components/shared/confirm-dialog'
import { useDispatch } from 'react-redux'

const PreviewVariants = ({ editable, product }: Props): ReactElement => {
  const variants = useSelector(selectProductVariants, product.id)
  const productAttributes = useSelector((state) =>
    selectProductAttributes(state, product.id),
  )
  const dispatch = useDispatch<AppDispatch>()
  const confirm = useConfirm()

  useEffect(() => {
    dispatch(getProductVariants(product))
  }, [product])

  const renderAttributeValues = ({
    attribute_values: attributeValues,
  }: VariantType) => {
    return (
      <List>
        {attributeValues.map((attributeValue) => (
          <ListItem key={attributeValue.id}>
            {attributeValue.attribute.name}: {attributeValue.name}
          </ListItem>
        ))}
      </List>
    )
  }

  const renderEditAttributeValues = useCallback(
    ({ onChange, rowData }) => {
      return (
        <SelectVariantAttributeValues
          attributeValuesSelected={rowData.attribute_values}
          onChange={onChange}
          productAttributes={productAttributes ?? []}
        />
      )
    },
    [productAttributes],
  )

  const handleCreateVariant = async (newVariant: NewVariantRowType) => {
    dispatch(
      createVariant({
        ...newVariant,
        product_id: product.id,
      }),
    )
  }

  const handleUpdateVariant = async (variant: VariantType) => {
    dispatch(updateVariant(variant))
  }

  const handleDeleteVariant = async (variant: VariantType) => {
    confirm({
      title: 'Be Careful',
      message: 'Are you sure you want to delete this variant of the product?',
      onConfirm: () => {
        dispatch(deleteVariant(variant))
      },
    })
  }

  return (
    <MaterialTable
      columns={[
        { title: 'SKU', field: 'sku', width: '40%' },
        {
          title: 'Attribute Values',
          field: 'attribute_value_ids',
          render: renderAttributeValues,
          editComponent: renderEditAttributeValues,
          width: '55%',
        },
      ]}
      data={variants ?? []}
      editable={
        editable
          ? {
              onRowUpdate: handleUpdateVariant,
              onRowAdd: handleCreateVariant,
              onRowDelete: handleDeleteVariant,
            }
          : {}
      }
      options={{
        actionsColumnIndex: 99,
        paging: false,
        addRowPosition: 'first',
        tableLayout: 'fixed',
      }}
      title='Variants'
    />
  )
}

const SelectVariantAttributeValues = ({
  productAttributes,
  attributeValuesSelected,
  onChange,
}: {
  productAttributes: ProductAttributeType[]
  onChange: (attributeValueIds: number[]) => void
  attributeValuesSelected?: VariantAttributeValue[]
}): ReactElement => {
  const { classes } = useStyles()
  const [selectedAttributes, setSelectedAttributes] = useState<
    Record<number, number>
  >({})

  useEffect(() => {
    if (attributeValuesSelected && attributeValuesSelected.length) {
      const preselectedValues = {}
      attributeValuesSelected.forEach((attributeValue) => {
        preselectedValues[attributeValue.attribute_id] = attributeValue.id
      })
      setSelectedAttributes(preselectedValues)
    }
  }, [])

  const handleChangeAttributeValue =
    (attributeId: number) =>
    ({ target: { value } }: SelectChangeEvent) => {
      const updatedAttributes = {
        ...selectedAttributes,
        [attributeId]: parseInt(value, 10),
      }
      setSelectedAttributes(updatedAttributes)
      onChange(Object.values(updatedAttributes).filter((x) => x))
    }

  return (
    <Grid container item md={8} xs={12}>
      <Grid container direction='column' item xs={4}>
        {productAttributes.map((productAttribute) => (
          <div
            className={classes.attributeValueListItem}
            key={productAttribute.id}
          >
            <Typography>{productAttribute.attribute?.name}:</Typography>
          </div>
        ))}
      </Grid>
      <Grid container direction='column' item xs={8}>
        {productAttributes.map((productAttribute) => (
          <div
            className={classes.attributeValueListItem}
            key={productAttribute.id}
          >
            <FormControl size='small' sx={{ minWidth: 120 }}>
              <Select
                onChange={handleChangeAttributeValue(
                  productAttribute.attribute_id,
                )}
                value={(
                  selectedAttributes[productAttribute.attribute_id] ?? 0
                ).toString()}
              >
                <MenuItem key={0} value={0}>
                  Unselected
                </MenuItem>
                {productAttribute.product_attribute_values.map(
                  (productAttributeValue) => (
                    <MenuItem
                      key={productAttributeValue.attribute_value?.id}
                      value={productAttributeValue.attribute_value?.id}
                    >
                      {productAttributeValue.attribute_value?.name}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </div>
        ))}
      </Grid>
    </Grid>
  )
}

export default PreviewVariants

type Props = {
  editable: boolean
  product: ProductType
}

type NewVariantRowType = {
  sku: string
}

const useStyles = makeStyles()(() => ({
  attributeValueListItem: {
    height: 56,
    alignContent: 'center',
  },
}))
