import ErrorBoundary from '@components/shared/error-boundary'
import React, { ReactElement, useEffect } from 'react'
import Table, { ColumnsType } from '@components/shared/table'
import { Add } from '@mui/icons-material'
import { AppDispatch, AttributeType } from '@types'
import { Paper } from '@mui/material'
import { deleteAttribute, getAttributes } from '@store/actions/attributes'
import { selectAttributes } from '@store/selectors/attributes'
import { useConfirm } from '@components/shared/confirm-dialog'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Attributes = (): ReactElement => {
  const confirm = useConfirm()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const attributes = useSelector(selectAttributes)

  useEffect(() => {
    dispatch(getAttributes())
  }, [])

  const columns: ColumnsType<AttributeType> = [
    { field: 'name', title: 'Display Name' },
    { field: 'label', title: 'Label' },
    { field: 'product_type', title: 'Product Type' },
  ]

  const handleAddNew = () => navigate('/attributes/create')
  const handleRowEdit = (row) => navigate(`/attributes/${row.id}/edit`)
  const handleRowDelete = (row) => {
    confirm({
      title: 'Be Careful',
      message: 'Are you sure you never want to use this attribute again?',
      onConfirm: () => {
        dispatch(deleteAttribute(row))
      },
    })
  }

  return (
    <>
      <ErrorBoundary>
        <Paper>
          <Table
            columns={columns}
            data={attributes}
            editing
            rowActions={{
              onRowUpdate: handleRowEdit,
              onRowDelete: handleRowDelete,
            }}
            rowKey='id'
            title='Attributes'
            toolbarButtons={[
              {
                title: 'Add New Attributes',
                onClick: handleAddNew,
                startIcon: <Add />,
              },
            ]}
          />
        </Paper>
      </ErrorBoundary>
    </>
  )
}

export default Attributes
