import {
  deleteProductAttributeAction,
  deleteProductAttributeValueAction,
} from '@store/actions/product-attributes'
import { getProductVariants } from '@store/actions/variants'
import { put, takeEvery } from 'redux-saga/effects'

const attributeDeletes = [
  deleteProductAttributeAction,
  deleteProductAttributeValueAction,
]

export function* watchDeleteProductAttributes() {
  yield takeEvery(attributeDeletes, updateVariants)
}

function* updateVariants({ payload }) {
  const productId = payload.product_id ?? payload.product_attribute?.product_id
  if (productId) {
    yield put(getProductVariants({ id: productId } as any))
  }
}
