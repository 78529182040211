import * as api from '@utils/api'
import AttributeForm from './attribute-form'
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import useSelector from '@hooks/use-selector'
import { AppDispatch, AttributeValueType, ProductTypeType } from '@types'
import {
  Button,
  Grid,
  List,
  ListItem,
  ListSubheader,
  Paper,
  TextField,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { getAttributes, updateAttribute } from '@store/actions/attributes'
import { selectAttribute } from '@store/selectors/attributes'
import { setFlashMessage } from '@store/actions'
import { useConfirm } from '@components/shared/confirm-dialog'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const EditAttribute = (): ReactElement => {
  const confirm = useConfirm()
  const { attributeId } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const attribute = useSelector(
    selectAttribute,
    attributeId ? parseInt(attributeId, 10) : undefined,
  )
  const [name, setName] = useState('')
  const [label, setLabel] = useState('')
  const [productType, setProductType] = useState<ProductTypeType>('beverage')
  const [attributeValues, setAttributeValues] = useState<AttributeValueType[]>(
    [],
  )

  useEffect(() => {
    if (!attribute) dispatch(getAttributes())
  }, [attributeId])

  useEffect(() => {
    if (!attribute) return
    setName(attribute.name)
    setLabel(attribute.label)
    setProductType(attribute.product_type)
    setAttributeValues(attribute.attribute_values)
  }, [attribute])

  if (!attribute) return <></>

  const handleAttributeValueChange =
    (i: number) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setAttributeValues(
        attributeValues.with(i, {
          ...attributeValues[i],
          name: value as string,
        }),
      )
    }

  const handleAttributeValueDelete =
    (i: number, attributeValueId: number) => () => {
      const continueDelete = () => {
        setAttributeValues(
          attributeValues.with(i, {
            ...attributeValues[i],
            deleted: true,
          }),
        )
      }

      if (attributeValueId == 0) {
        continueDelete()
        return
      }

      confirm({
        title: 'Be Careful',
        message:
          'Are you sure you want to never use this attribute value again?',
        onConfirm: continueDelete,
      })
    }

  const handleAttributeValueAdd = () => {
    setAttributeValues([
      ...attributeValues,
      {
        id: 0,
        name: '',
      },
    ])
  }

  const handleUpdate = async () => {
    try {
      await handleUpdateAttributeValues()
    } catch (error: any) {
      dispatch(setFlashMessage(error.message, 'error'))
      setAttributeValues(attribute.attribute_values)
      return
    }
    dispatch(
      updateAttribute({
        ...attribute,
        name,
        label,
        product_type: productType,
      }),
    )
  }

  const handleUpdateAttributeValues = async () => {
    const updates = await Promise.all(
      attributeValues
        .map((attributeValue) => {
          if (attributeValue.deleted) {
            if (attributeValue.id == 0) return

            return api.DELETE(
              `/p4/preview/attributeValues/${attributeValue.id}`,
            )
          } else if (attributeValue.id == 0) {
            return api.POST(`/p4/preview/attributeValues`, {
              attribute_id: attribute.id,
              name: attributeValue.name,
            })
          } else {
            return api.PUT(`/p4/preview/attributeValues/${attributeValue.id}`, {
              name: attributeValue.name,
            })
          }
        })
        .filter((x) => x),
    )

    return updates
  }

  return (
    <>
      <h1>{attribute.name}</h1>
      <AttributeForm
        label={label}
        name={name}
        onLabelChange={setLabel}
        onNameChange={setName}
        onProductTypeChange={setProductType}
        productType={productType}
      />
      <Paper sx={{ mt: 5 }}>
        <List subheader={<ListSubheader>Attribute Values</ListSubheader>}>
          {attributeValues.map((attributeValue, i) => {
            if (attributeValue.deleted) return null
            return (
              <ListItem key={i}>
                <TextField
                  onChange={handleAttributeValueChange(i)}
                  value={attributeValue.name}
                />
                <Button
                  onClick={handleAttributeValueDelete(i, attributeValue.id)}
                >
                  <Delete />
                </Button>
              </ListItem>
            )
          })}
          <ListItem key='new'>
            <Button
              color='secondary'
              onClick={handleAttributeValueAdd}
              variant='contained'
            >
              Add Attribute Value
            </Button>
          </ListItem>
        </List>
      </Paper>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          py: 2,
        }}
      >
        <Grid item>
          <Button color='secondary' onClick={handleUpdate} variant='contained'>
            Update
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default EditAttribute
