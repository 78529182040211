import DndTable from '@components/shared/dnd-table'
import PreviewAddProductAttributeValueDialog from './add-product-attribute-value-dialog'
import React, { ReactElement, useState } from 'react'
import { Add, Delete } from '@mui/icons-material'
import {
  AppDispatch,
  AttributeValueType,
  ProductAttributeValueType,
} from '@types'
import { Checkbox } from '@mui/material'
import {
  createProductAttributeValue,
  deleteProductAttributeValue,
  updateProductAttributeValue,
} from '@store/actions/product-attributes'
import { useConfirm } from '@components/shared/confirm-dialog'
import { useDispatch } from 'react-redux'

const PreviewAttributeValues = ({
  attributeValues,
  editable,
  attributeId,
  productAttributeId,
  title,
}: Props): ReactElement => {
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const confirm = useConfirm()

  const handleAddDialogOpen = () => setAddDialogOpen(true)
  const handleAddCancel = () => setAddDialogOpen(false)
  const handleRowClick = () => {}

  const handleSort = (sortedAttributeValues: ProductAttributeValueType[]) => {
    sortedAttributeValues.forEach((sortedAttributeValue, i) => {
      dispatch(
        updateProductAttributeValue({
          ...sortedAttributeValue,
          sort_order: i,
        }),
      )
    })
  }

  const handleAddConfirm = (attributeValue: AttributeValueType) => {
    dispatch(
      createProductAttributeValue({
        attribute_value_id: attributeValue.id,
        product_attribute_id: productAttributeId,
        sort_order: 0,
        is_default: false,
      }),
    )

    setAddDialogOpen(false)
  }

  const handleDelete = (productAttributeValue: ProductAttributeValueType) => {
    confirm({
      title: 'Be Careful',
      message:
        'Are you sure you want to delete this value? Removing an attribute value that is currently tied to product variants will cause those products to immediately disappear from the menu.',
      onConfirm: () => {
        dispatch(deleteProductAttributeValue(productAttributeValue))
      },
    })
  }

  const handleDefaultChange =
    (productAttributeValueId: number) => (_, checked: boolean) => {
      const attributeValue = attributeValues.find(
        (x) => x.id == productAttributeValueId,
      )
      if (!attributeValue) return
      if (!checked && attributeValue.is_default) return

      if (checked) {
        attributeValues.forEach((otherAttributeValue) => {
          if (
            otherAttributeValue.is_default &&
            otherAttributeValue.id != productAttributeValueId
          ) {
            handleUpdate({
              ...otherAttributeValue,
              is_default: false,
            })
          }
        })
      }

      handleUpdate({
        ...attributeValue,
        is_default: checked,
      })
    }

  const handleUpdate = (productAttributeValue: ProductAttributeValueType) => {
    dispatch(updateProductAttributeValue(productAttributeValue))
  }

  return (
    <>
      <PreviewAddProductAttributeValueDialog
        attributeId={attributeId}
        onCancel={handleAddCancel}
        onConfirm={handleAddConfirm}
        open={addDialogOpen}
      />
      <DndTable
        actions={
          editable
            ? [
                {
                  title: 'Add Attribute Value',
                  icon: <Add />,
                  toolbarAction: true,
                  onClick: handleAddDialogOpen,
                },
                {
                  title: 'Delete',
                  icon: <Delete />,
                  toolbarAction: false,
                  onClick: handleDelete,
                },
              ]
            : []
        }
        afterDrop={handleSort}
        columns={[
          {
            Header: 'Name',
            accessor: 'attribute_value.name',
          },
          {
            Header: 'Default',
            accessor: 'is_default',
            Cell: ({
              value: isDefault,
              row: { id },
            }: {
              value: boolean
              row: { id: number }
            }) => (
              <Checkbox
                checked={isDefault}
                disabled={!editable}
                onChange={handleDefaultChange(id)}
              />
            ),
          },
        ]}
        data={attributeValues}
        disableDrag={!editable}
        hideBorders
        hideHeaders={false}
        layout='tight'
        rowClick={handleRowClick}
        sortColumn={['sort_order']}
        sortDir={['asc']}
        title={title}
      />
    </>
  )
}

export default PreviewAttributeValues

type Props = {
  attributeValues: ProductAttributeValueType[]
  editable: boolean
  title: string
  attributeId: number
  productAttributeId: number
}
