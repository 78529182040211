import AddTypeDialog from './add-type-dialog'
import DndTable from '@components/shared/dnd-table'
import React, { ReactElement, useState } from 'react'
import { Add, Delete } from '@mui/icons-material'
import { ProductAttributeData } from '@types'

interface Props {
  add(ProductAttributeData): Promise<void>
  attributes: ProductAttributeData[]
  className: string
  onClick(data): ProductAttributeData | void
  remove(data): Promise<void>
  reSort(data): void
  editable: boolean
}

const Attributes = ({
  add,
  attributes,
  className,
  onClick,
  remove,
  reSort,
  editable,
}: Props): ReactElement => {
  const [addDialogOpen, setAddDialogOpen]: [boolean, any] = useState(false)

  const rowClick = (id: number): void => {
    const clickedAttr = attributes.find(
      (attr: ProductAttributeData): boolean => {
        return attr.id === id
      },
    )

    onClick(clickedAttr)
  }

  const deleteAttr = (data: ProductAttributeData): void => {
    remove(data)
  }

  const addAttr = (attrName: string): void => {
    setAddDialogOpen(false)
    add(attrName)
  }

  return (
    <div className={className}>
      <AddTypeDialog
        onClose={() => {
          setAddDialogOpen(false)
        }}
        onConfirm={addAttr}
        open={addDialogOpen}
      />
      <DndTable
        actions={
          editable
            ? [
                {
                  title: 'Add Attribute Type',
                  icon: <Add />,
                  toolbarAction: true,
                  onClick: () => setAddDialogOpen(true),
                },
                {
                  title: 'Delete',
                  icon: <Delete />,
                  toolbarAction: false,
                  onClick: deleteAttr,
                },
              ]
            : []
        }
        afterDrop={reSort}
        columns={[{ Header: 'Name', accessor: 'name' }]}
        data={attributes}
        disableDrag={!editable}
        hideBorders
        hideHeaders
        layout='tight'
        rowClick={rowClick}
        sortColumn={['sort_order']}
        sortDir={['asc']}
        title='Attributes'
      />
    </div>
  )
}

export default Attributes
