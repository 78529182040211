export type ProductList = ProductSummary[]

export type ProductTypeType =
  | 'beverage'
  | 'food'
  | 'bag'
  | 'bottle'
  | 'merchandise'

export const productTypesList: ProductTypeType[] = [
  'beverage',
  'food',
  'bag',
  'bottle',
  'merchandise',
]

export interface ProductSummary {
  id: number
  name: string
  product_type: ProductTypeType
  square_sku: string
}

export interface ProductRecommendation {
  id: number
  product_id: number
  recommended_product_ids: number[]
  sort_order: number
}

export type ProductRecommendationObject = {
  [key: string]: ProductRecommendation
}

export type ProductType = {
  id: number
  description: string
  foreground_color: string | null
  background_color: string | null
  tertiary_color: string | null
  addons: any
  archived_at: null | string
  blend_type: string
  calories: any
  image_url: string
  is_inventory_controlled: boolean
  is_seasonal: boolean
  mobile_menu_format: string
  name: string
  netsuite_account_id: number
  on_default_menu: boolean
  prep_time: any
  price: number
  product_attributes: any
  product_type: ProductTypeType
  square_sku: string
  tags: any
}
