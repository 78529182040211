import React, { ReactElement, useEffect } from 'react'
import useSelector from '@hooks/use-selector'
import { AppDispatch, PromotionalCategoryType } from '@types'
import { Checkbox, FormControlLabel } from '@mui/material'
import { getPromotionalCategories } from '@store/actions/promotional-categories'
import { selectPromotionalCategories } from '@store/selectors/promotional-categories'
import { useDispatch } from 'react-redux'

const PromotionalCategoriesCheckboxes = ({
  onChange,
  value,
  disabled = false,
  inverseCheckedValues = false,
}: Props): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const promotionalCategories = useSelector(selectPromotionalCategories)

  useEffect(() => {
    if (!promotionalCategories.length) {
      dispatch(getPromotionalCategories())
    }
  }, [])

  const isChecked = (id: number) => {
    return !!value.find((x) => x.id == id) != inverseCheckedValues
  }

  const handleCheckboxChanged = (id: number) => (_, checked: boolean) => {
    const selectedIds = new Set(value.map((x) => x.id))
    if (checked != inverseCheckedValues) {
      selectedIds.add(id)
    } else {
      selectedIds.delete(id)
    }

    onChange(promotionalCategories.filter((x) => selectedIds.has(x.id)))
  }

  if (!promotionalCategories.length) return <></>

  return (
    <>
      {promotionalCategories.map((category) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked(category.id)}
              disabled={disabled}
              onChange={handleCheckboxChanged(category.id)}
              value={category.id}
            />
          }
          key={category.id}
          label={category.name}
        />
      ))}
    </>
  )
}

export default PromotionalCategoriesCheckboxes

type Props = {
  onChange: (value: PromotionalCategoryType[]) => void
  value: PromotionalCategoryType[]
  disabled?: boolean
  inverseCheckedValues?: boolean
}
