import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Paper,
  TextField,
} from '@mui/material'
import { ProductTypeType, PromotionType, productTypesList } from '@types'
import { capitalizedFirstLetter } from '@utils/string-utils'
import { makeStyles } from 'tss-react/mui'

const WholeOrder = ({
  className,
  promotion,
  editing,
  onConfigurationChange,
}: PropsType): ReactElement => {
  const { classes } = useStyles()
  const [productTypes, setProductTypes] = useState<ProductTypeType[]>([])
  const [centsOff, setCentsOff] = useState(0)

  const currentConfig = promotion ? promotion.configuration : {}
  const config: ConfigurationType = {
    triggering_product_types: productTypesList,
    cents_off: 0,
    ...currentConfig,
  }

  const resetConfiguration = () => {
    setProductTypes(config.triggering_product_types)
    setCentsOff(config.cents_off)
  }

  useEffect(() => {
    resetConfiguration()
  }, [])

  useEffect(() => {
    if (!promotion) return

    resetConfiguration()
  }, [promotion])

  const handleNewConfiguration = (newValues = {}) => {
    onConfigurationChange({
      triggering_product_types: productTypes,
      cents_off: centsOff,
      ...newValues,
    })
  }

  const handleCentsOffChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const newValue = value ? parseInt(value, 10) : 0
    setCentsOff(newValue)
    handleNewConfiguration({ cents_off: newValue })
  }

  const handleProductTypeChange =
    (productType: ProductTypeType) => (_, checked: boolean) => {
      const wasChecked = productTypes.includes(productType)
      let newValue: null | ProductTypeType[] = null
      if (wasChecked && !checked) {
        newValue = productTypes.filter((x) => x != productType)
      } else if (!wasChecked && checked) {
        newValue = [...productTypes, productType]
      }
      if (newValue) {
        setProductTypes(newValue)
        handleNewConfiguration({ triggering_product_types: newValue })
      }
    }

  return (
    <div className={className || ''}>
      <Paper className={classes.paperWrapper}>
        <div>
          <TextField
            disabled={!editing}
            label='Cents Off'
            onChange={handleCentsOffChange}
            value={centsOff}
          />

          <p>
            The entire order will be discounted by this much as long as the line
            items that qualify equal or exceed the value of the discount.
          </p>
        </div>

        <div>
          <List>
            {productTypesList.map((productType) => (
              <ListItem key={productType}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productTypes.includes(productType)}
                      disabled={!editing}
                      onChange={handleProductTypeChange(productType)}
                    />
                  }
                  label={capitalizedFirstLetter(productType)}
                />
              </ListItem>
            ))}
          </List>

          <p>
            The discount will only be applied to line items which are included
            in these product types.
          </p>
        </div>
      </Paper>
    </div>
  )
}

export default WholeOrder

type PropsType = {
  className: string | null
  promotion: PromotionType | null
  editing: boolean
  onConfigurationChange: (object) => void
}

type ConfigurationType = {
  triggering_product_types: ProductTypeType[]
  cents_off: number
}

const useStyles = makeStyles()((theme) => ({
  paperWrapper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: theme.spacing(2),
  },
}))
