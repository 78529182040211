import createAction from './create-action'
import {
  ActionPayloadWithResource,
  ApiDispatch,
  NewProductAttributeType,
  NewProductAttributeValueType,
  ProductAttributeType,
  ProductAttributeValueType,
  ProductType,
} from '@types'
import {
  CLONE_PRODUCT_ATTRIBUTES,
  CLONE_PRODUCT_ATTRIBUTES_FAILURE,
  CREATE_PRODUCT_ATTRIBUTE,
  CREATE_PRODUCT_ATTRIBUTE_FAILURE,
  CREATE_PRODUCT_ATTRIBUTE_VALUE,
  CREATE_PRODUCT_ATTRIBUTE_VALUE_FAILURE,
  DELETE_PRODUCT_ATTRIBUTE,
  DELETE_PRODUCT_ATTRIBUTE_FAILURE,
  DELETE_PRODUCT_ATTRIBUTE_VALUE,
  DELETE_PRODUCT_ATTRIBUTE_VALUE_FAILURE,
  GET_PRODUCT_ATTRIBUTES,
  GET_PRODUCT_ATTRIBUTES_FAILURE,
  SET_PRODUCT_ATTRIBUTE,
  UPDATE_PRODUCT_ATTRIBUTE,
  UPDATE_PRODUCT_ATTRIBUTE_FAILURE,
  UPDATE_PRODUCT_ATTRIBUTE_VALUE,
  UPDATE_PRODUCT_ATTRIBUTE_VALUE_FAILURE,
} from '@store/action-list'
import { apiAction } from './api'

export const setProductAttributeAction = createAction<ProductAttributeType>(
  SET_PRODUCT_ATTRIBUTE,
)

export const getProductAttributesAction = createAction<
  ActionPayloadWithResource<ProductAttributeType[], number>
>(GET_PRODUCT_ATTRIBUTES)
const getProductAttributesFailureAction = createAction(
  GET_PRODUCT_ATTRIBUTES_FAILURE,
)

export const createProductAttributeAction = createAction<ProductAttributeType>(
  CREATE_PRODUCT_ATTRIBUTE,
)
const createProductAttributeFailureAction = createAction(
  CREATE_PRODUCT_ATTRIBUTE_FAILURE,
)

export const updateProductAttributeAction = createAction<ProductAttributeType>(
  UPDATE_PRODUCT_ATTRIBUTE,
)
const updateProductAttributeFailureAction = createAction(
  UPDATE_PRODUCT_ATTRIBUTE_FAILURE,
)

export const deleteProductAttributeAction = createAction<ProductAttributeType>(
  DELETE_PRODUCT_ATTRIBUTE,
)
const deleteProductAttributeFailureAction = createAction(
  DELETE_PRODUCT_ATTRIBUTE_FAILURE,
)

export const createProductAttributeValueAction =
  createAction<ProductAttributeValueType>(CREATE_PRODUCT_ATTRIBUTE_VALUE)
const createProductAttributeValueFailureAction = createAction(
  CREATE_PRODUCT_ATTRIBUTE_VALUE_FAILURE,
)

export const deleteProductAttributeValueAction =
  createAction<ProductAttributeValueType>(DELETE_PRODUCT_ATTRIBUTE_VALUE)
const deleteProductAttributeValueFailureAction = createAction(
  DELETE_PRODUCT_ATTRIBUTE_VALUE_FAILURE,
)

export const updateProductAttributeValueAction =
  createAction<ProductAttributeValueType>(UPDATE_PRODUCT_ATTRIBUTE_VALUE)
const updateProductAttributeValueFailureAction = createAction(
  UPDATE_PRODUCT_ATTRIBUTE_VALUE_FAILURE,
)

export const cloneProductAttributesAction = createAction<
  ActionPayloadWithResource<ProductAttributeType[], number>
>(CLONE_PRODUCT_ATTRIBUTES)
const cloneProductAttributesFailureAction = createAction(
  CLONE_PRODUCT_ATTRIBUTES_FAILURE,
)

export const getProductAttributes = (product: ProductType): ApiDispatch =>
  apiAction({
    url: `/p4/preview/products/${product.id}/productAttributes`,
    method: 'GET',
    actionCallbacks: {
      success: getProductAttributesAction,
      failure: getProductAttributesFailureAction,
    },
    resource: product.id,
  })

export const createProductAttribute = (
  attribute: NewProductAttributeType,
): ApiDispatch =>
  apiAction({
    url: '/p4/preview/productAttributes',
    method: 'POST',
    data: attribute,
    actionCallbacks: {
      success: createProductAttributeAction,
      failure: createProductAttributeFailureAction,
    },
  })

export const updateProductAttribute = (
  attribute: ProductAttributeType,
): ApiDispatch =>
  apiAction({
    url: `/p4/preview/productAttributes/${attribute.id}`,
    method: 'PUT',
    data: attribute,
    actionCallbacks: {
      success: updateProductAttributeAction,
      failure: updateProductAttributeFailureAction,
    },
  })

export const deleteProductAttribute = (
  attribute: ProductAttributeType,
): ApiDispatch =>
  apiAction({
    url: `/p4/preview/productAttributes/${attribute.id}`,
    method: 'DELETE',
    actionCallbacks: {
      success: deleteProductAttributeAction,
      failure: deleteProductAttributeFailureAction,
    },
  })

export const createProductAttributeValue = (
  productAttributeValue: NewProductAttributeValueType,
): ApiDispatch =>
  apiAction({
    url: '/p4/preview/productAttributeValues',
    method: 'POST',
    data: productAttributeValue,
    actionCallbacks: {
      success: createProductAttributeValueAction,
      failure: createProductAttributeValueFailureAction,
    },
  })

export const updateProductAttributeValue = (
  productAttributeValue: ProductAttributeValueType,
): ApiDispatch =>
  apiAction({
    url: `/p4/preview/productAttributeValues/${productAttributeValue.id}`,
    method: 'PUT',
    data: productAttributeValue,
    actionCallbacks: {
      success: updateProductAttributeValueAction,
      failure: updateProductAttributeValueFailureAction,
    },
  })

export const deleteProductAttributeValue = (
  productAttributeValue: ProductAttributeValueType,
): ApiDispatch =>
  apiAction({
    url: `/p4/preview/productAttributeValues/${productAttributeValue.id}`,
    method: 'DELETE',
    actionCallbacks: {
      success: deleteProductAttributeValueAction,
      failure: deleteProductAttributeValueFailureAction,
    },
  })

export const cloneProductAttributes = ({
  productId,
  cloneProductId,
}: {
  productId: number
  cloneProductId: number
}): ApiDispatch =>
  apiAction({
    url: `/p4/preview/productAttributes/clone`,
    method: 'POST',
    data: {
      product_id: productId,
      clone_product_id: cloneProductId,
    },
    actionCallbacks: {
      success: cloneProductAttributesAction,
      failure: cloneProductAttributesFailureAction,
    },
  })
