import React, { ReactElement, createContext, useContext, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

// A confirmation dialog for extremely simple use cases
// and a context hook for callback pattern
const ConfirmContext = createContext<{
  openConfirm: (args: ConfirmDataArgsType) => void
} | null>(null)

const ConfirmProvider = ({ children }): ReactElement => {
  const [showConfirm, setShowConfirm] = useState(false)
  const [dialogData, setDialogData] = useState<ConfirmDataType>({
    title: null,
    message: '',
    onConfirm: () => true,
    onCancel: () => false,
  })

  const openConfirm = ({
    message,
    title = null,
    onConfirm = () => true,
    onCancel = () => false,
  }: ConfirmDataArgsType) => {
    setDialogData({
      title,
      message,
      onConfirm,
      onCancel,
    })
    setShowConfirm(true)
  }

  const closeDialog = () => {
    setShowConfirm(false)
  }

  const handleConfirm = () => {
    dialogData.onConfirm()
    closeDialog()
  }

  const handleCancel = () => {
    dialogData.onCancel()
    closeDialog()
  }

  return (
    <ConfirmContext.Provider value={{ openConfirm }}>
      <Dialog open={showConfirm}>
        {dialogData.title && <DialogTitle>{dialogData.title}</DialogTitle>}
        <DialogContent>
          <DialogContentText>{dialogData.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button color='secondary' onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </ConfirmContext.Provider>
  )
}

const useConfirm = () => {
  const context = useContext(ConfirmContext)
  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmProvider')
  }
  return context.openConfirm
}

export { ConfirmProvider, useConfirm }

type ConfirmDataType = {
  message: string
  title: string | null
  onConfirm: () => void
  onCancel: () => void
}

type ConfirmDataArgsType = {
  message: string
  title?: string | null
  onConfirm?: () => void
  onCancel?: () => void
}
