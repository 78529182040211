import CloneFromDialog from '../clone-from-dialog'
import PreviewAddProductAttributeDialog from './add-product-attribute-dialog'
import React, { ReactElement, useState } from 'react'
import { Add, FilterNone } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { ProductTypeType } from '@types'
import { makeStyles } from 'tss-react/mui'

const PreviewEmptyProductAttributes = ({
  onAddAttribute,
  onCloneProduct,
  productType,
}: Props): ReactElement => {
  const [addDialogOpen, setAddDialogOpen]: [boolean, any] = useState(false)
  const [cloneDialogOpen, setCloneDialogOpen]: [boolean, any] = useState(false)
  const { classes } = useStyles()

  const handleClone = async (productId: number) => {
    onCloneProduct(productId)
    setCloneDialogOpen(false)
  }

  const handleOpenAddDialog = () => {
    setAddDialogOpen(true)
  }

  const handleCancelAddDialog = () => {
    setAddDialogOpen(false)
  }

  const handleAddAttribute = (attributeId: number) => {
    onAddAttribute(attributeId)
    setAddDialogOpen(false)
  }

  return (
    <div className={classes.container}>
      <PreviewAddProductAttributeDialog
        onCancel={handleCancelAddDialog}
        onConfirm={handleAddAttribute}
        open={addDialogOpen}
        productType={productType}
      />
      <CloneFromDialog
        onClose={() => {
          setCloneDialogOpen(false)
        }}
        onConfirm={handleClone}
        open={cloneDialogOpen}
        productType={productType}
      />
      <Typography align='center' className={classes.content}>
        It seems you have not added any attributes.
      </Typography>
      <Button
        onClick={() => setCloneDialogOpen(true)}
        startIcon={<FilterNone />}
        variant='outlined'
      >
        Clone from existing product
      </Button>
      <Typography align='center'>OR</Typography>
      <Button
        onClick={handleOpenAddDialog}
        startIcon={<Add />}
        variant='outlined'
      >
        Add Attribute Type
      </Button>
    </div>
  )
}

export default PreviewEmptyProductAttributes

const useStyles = makeStyles()((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
  },
  content: {
    width: '300px',
    marginBottom: theme.spacing(3),
  },
}))

type Props = {
  onAddAttribute: (attributeId: number) => void
  onCloneProduct: (productId: number) => void
  productType: ProductTypeType
}
