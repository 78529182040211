import MaterialTable from '@material-table/core'
import React, { ReactElement, useEffect } from 'react'
import hasPerms from '@utils/has-permission'
import useSelector from '@hooks/use-selector'
import {
  AppDispatch,
  NewPromotionalCategoryType,
  PromotionalCategoryType,
} from '@types'
import {
  createPromotionalCategory,
  deletePromotionalCategory,
  getPromotionalCategories,
  updatePromotionalCategory,
} from '@store/actions/promotional-categories'
import { selectPromotionalCategories } from '@store/selectors/promotional-categories'
import { useDispatch } from 'react-redux'

const PromotionalCategories = (): ReactElement => {
  const categories = useSelector(selectPromotionalCategories)
  const canEdit = hasPerms('promotions:update')
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getPromotionalCategories())
  }, [])

  const handleUpdate = async (category: PromotionalCategoryType) => {
    dispatch(updatePromotionalCategory(category))
  }

  const handleCreate = async (newCategory: NewPromotionalCategoryType) => {
    dispatch(createPromotionalCategory(newCategory))
  }

  const handleDelete = async (category: PromotionalCategoryType) => {
    dispatch(deletePromotionalCategory(category))
  }

  return (
    <MaterialTable
      columns={[{ title: 'Name', field: 'name' }]}
      data={categories}
      editable={
        canEdit
          ? {
              onRowUpdate: handleUpdate,
              onRowAdd: handleCreate,
              onRowDelete: handleDelete,
            }
          : {}
      }
      options={{
        actionsColumnIndex: 99,
        paging: false,
        addRowPosition: 'first',
        tableLayout: 'fixed',
        search: false,
      }}
      title='Promotional Categories'
    />
  )
}

export default PromotionalCategories
