import assert from 'assert'
import { ProductAttributeType } from '@types'
import { arrayToObject } from '@utils/functional'
import {
  cloneProductAttributesAction,
  createProductAttributeAction,
  createProductAttributeValueAction,
  deleteProductAttributeAction,
  deleteProductAttributeValueAction,
  getProductAttributesAction,
  setProductAttributeAction,
  updateProductAttributeAction,
  updateProductAttributeValueAction,
} from '@store/actions/product-attributes'
import { createReducer } from '@reduxjs/toolkit'

type State = Record<number, Record<number, ProductAttributeType>>

const initialState = {} as State

const setOneProductAttribute = (state, { payload: productAttribute }) => {
  const productAttributes = {
    ...state[productAttribute.product_id],
    [productAttribute.id]: productAttribute,
  }
  return { ...state, [productAttribute.product_id]: productAttributes }
}

const setOneProductAttributeValue = (
  state,
  { payload: productAttributeValue },
) => {
  assert(
    productAttributeValue.product_attribute,
    'Payload is missing product_attribute.',
  )
  const oldProductAttribute =
    state[productAttributeValue.product_attribute.product_id][
      productAttributeValue.product_attribute.id
    ]
  const newProductAttribute = {
    ...oldProductAttribute,
    product_attribute_values: [
      ...oldProductAttribute.product_attribute_values.filter(
        (x) => x.id != productAttributeValue.id,
      ),
      productAttributeValue,
    ],
  }
  return setOneProductAttribute(state, { payload: newProductAttribute })
}

const setProductAttributes = (
  state,
  { payload: { payload: productAttributes, resource: productId } },
) => {
  return {
    ...state,
    [productId]: arrayToObject('id', productAttributes),
  }
}

const productAttributesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getProductAttributesAction, setProductAttributes)
    .addCase(cloneProductAttributesAction, setProductAttributes)
    .addCase(setProductAttributeAction, setOneProductAttribute)
    .addCase(updateProductAttributeAction, setOneProductAttribute)
    .addCase(createProductAttributeAction, setOneProductAttribute)
    .addCase(
      deleteProductAttributeAction,
      (state, { payload: productAttribute }) => {
        const productAttributes = {
          ...state[productAttribute.product_id],
        }
        delete productAttributes[productAttribute.id]
        return { ...state, [productAttribute.product_id]: productAttributes }
      },
    )
    .addCase(createProductAttributeValueAction, setOneProductAttributeValue)
    .addCase(updateProductAttributeValueAction, setOneProductAttributeValue)
    .addCase(
      deleteProductAttributeValueAction,
      (state, { payload: productAttributeValue }) => {
        assert(
          productAttributeValue.product_attribute,
          'Payload is missing product_attribute.',
        )
        const productAttribute = {
          ...state[productAttributeValue.product_attribute.product_id][
            productAttributeValue.product_attribute.id
          ],
        }
        productAttribute.product_attribute_values =
          productAttribute.product_attribute_values.filter(
            (x) => x.id != productAttributeValue.id,
          )
        return setOneProductAttribute(state, { payload: productAttribute })
      },
    )
})

export default productAttributesReducer
