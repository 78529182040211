import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'

import './index.css'
import * as serviceWorker from './serviceWorker'

import App from './App'
import { ConfirmProvider } from '@components/shared/confirm-dialog'
import { persistor, store } from './store'

const queryClient = new QueryClient()
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ConfirmProvider>
            <App />
          </ConfirmProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
)

serviceWorker.unregister()
