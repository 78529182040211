import React, { ReactElement, useEffect, useState } from 'react'
import ResponsiveDialog from '@components/shared/responsive-dialog'
import useSelector from '@hooks/use-selector'
import { AppDispatch, ProductTypeType } from '@types'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { getAttributes } from '@store/actions/attributes'
import { selectAttributesByProductType } from '@store/selectors/attributes'
import { useDispatch } from 'react-redux'

const PreviewAddProductAttributeDialog = ({
  open,
  onCancel: handleCancel,
  onConfirm,
  productType,
}: Props): ReactElement => {
  const attributes = useSelector(selectAttributesByProductType, productType)
  const dispatch = useDispatch<AppDispatch>()
  const [selectedAttribute, setSelectedAttribute] = useState(0)

  useEffect(() => {
    if (attributes && attributes.length) return

    dispatch(getAttributes())
  }, [])

  const handleAttributeChange = ({
    target: { value },
  }: SelectChangeEvent<number>) => {
    setSelectedAttribute(value as number)
  }

  const handleConfirm = () => {
    onConfirm(selectedAttribute)
  }

  return (
    <ResponsiveDialog open={open}>
      <DialogTitle>
        <Typography align='center'>Select New Attribute</Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Attribute</InputLabel>
          <Select onChange={handleAttributeChange} value={selectedAttribute}>
            {attributes.map((attribute) => (
              <MenuItem key={attribute.id} value={attribute.id}>
                {attribute.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button color='secondary' onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default PreviewAddProductAttributeDialog

type Props = {
  open: boolean
  onCancel: () => void
  onConfirm: (attributeId: number) => void
  productType: ProductTypeType
}
