import MultipleSelect from './multiple-select'
import React, { ReactElement, useEffect } from 'react'
import useSelector from '@hooks/use-selector'
import { AppDispatch, PromotionalCategoryType } from '@types'
import { getPromotionalCategories } from '@store/actions/promotional-categories'
import { selectPromotionalCategories } from '@store/selectors/promotional-categories'
import { useDispatch } from 'react-redux'

const PromotionalCategoriesSelect = ({
  onChange,
  value,
  ...props
}: Props): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const promotionalCategories = useSelector(selectPromotionalCategories)

  useEffect(() => {
    if (!promotionalCategories.length) {
      dispatch(getPromotionalCategories())
    }
  }, [])

  if (!promotionalCategories.length) return <></>

  return (
    <MultipleSelect
      onChange={onChange}
      optionKey='id'
      options={promotionalCategories}
      optionTitleKey='name'
      optionValueKey='id'
      selectedOptions={value}
      title='Promotional Categories'
      {...props}
    />
  )
}

export default PromotionalCategoriesSelect

type Props = {
  onChange: (options: PromotionalCategoryType[]) => void
  value: PromotionalCategoryType[]
}
